import { SalesSessionFragment, VehicleFragment } from 'backend/types.codegen';

import { Maybe } from 'types/utils';

import { getExternalBusServiceOffers } from './groupJourney';
import { isMatchingGroupJourney } from './toGroupJourneyKey';

export const getPriceOfCancellationInsurance = (salesSession: SalesSessionFragment) =>
  salesSession.groupJourneys.reduce(
    (acc, groupJourney) =>
      acc +
      (groupJourney.passengerJourneys?.reduce(
        (acc, passengerJourney) => acc + (passengerJourney.cancellationInsuranceOffer?.price || 0),
        0,
      ) || 0),
    0,
  );

export const getPriceOfVehicleCancellationInsurance = (vehicles: Maybe<VehicleFragment>[]) =>
  vehicles?.reduce((acc, vehicle) => acc + (vehicle?.cancellationInsuranceOffer?.price || 0), 0);

export const hasExternalBusConnections = (salesSession: Maybe<SalesSessionFragment>) =>
  getExternalBusServices(salesSession).length > 0;

export const getExternalBusServices = (salesSession: Maybe<SalesSessionFragment>) =>
  salesSession?.groupJourneys.flatMap(getExternalBusServiceOffers) ?? [];

export const getExternalBusServicesPrice = (salesSession: Maybe<SalesSessionFragment>) =>
  getExternalBusServices(salesSession).reduce(
    (acc, externalService) => acc + externalService.price + (externalService.fee?.price ?? 0),
    0,
  );

export const getJourney = (salesSession: SalesSessionFragment, journeyKey: string) =>
  salesSession.groupJourneys.find(isMatchingGroupJourney(journeyKey));
