import { useSalesSession } from 'backend/hooks/useSalesSession';

import { SalesSessionError } from 'components/structural/ErrorBoundary';

import { useIsMounted } from 'contexts/isMounted';
import { useSalesSessionUrlParam } from './useSalesSessionUrlParam';
import { useWrapSalesSession } from './useWrapSalesSession';

export const useSalesSessionFromUrlParam = ({
  throwOnError = true,
  requireIdInUrl: requireIdInUrl = true,
  skip,
}: {
  throwOnError?: boolean;
  requireIdInUrl?: boolean;
  skip?: boolean;
} = {}) => {
  const { saleId } = useSalesSessionUrlParam();
  const mounted = useIsMounted();

  if (mounted && !skip && !saleId && requireIdInUrl) {
    throw new SalesSessionError('saleSessionId missing in url parameters');
  }

  const response = useSalesSession(skip ? undefined : saleId, { throwOnError });

  return useWrapSalesSession(skip ? undefined : response.data?.salesSession, response);
};
