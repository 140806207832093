import Markdown from 'markdown-to-jsx';

import { getValue } from 'utils/object';

import type { ContentfulNodeProps } from '../RichText.types';
import { createRendererWithNode } from '../utils/createRenderer';

enum InlineEntryContentTypes {
  TRANSLATION = 'translation',
  LONG_TRANSLATION = 'longTranslation',
}

export const TranslationInline = ({ node }: ContentfulNodeProps) => {
  return node.data.target.fields.text || null;
};

export const LongTranslationInline = ({ node }: ContentfulNodeProps) => {
  return <Markdown>{node.data.target.fields.text || ''}</Markdown>;
};

const renderers: {
  [key in InlineEntryContentTypes]: (node: ContentfulNodeProps['node']) => JSX.Element;
} = {
  [InlineEntryContentTypes.TRANSLATION]: createRendererWithNode(TranslationInline),
  [InlineEntryContentTypes.LONG_TRANSLATION]: createRendererWithNode(LongTranslationInline),
};

export const EmbeddedEntryInline = ({ node }: ContentfulNodeProps) => {
  const contentId: string | undefined = node.data.target?.sys?.contentType?.sys?.id;
  const renderer = (contentId && getValue(renderers, contentId)) || null;

  return renderer && renderer(node);
};
