import { ApolloError } from '@apollo/client';
import { GraphQLFormattedError } from 'graphql';

import { SalesSessionError } from 'components/structural/ErrorBoundary';

const isExpirationError = ({ extensions }: GraphQLFormattedError) =>
  extensions && extensions.name && typeof extensions.name === 'string'
    ? ['SaleNotFound', 'SaleExpiredError', 'SaleNotFoundError'].includes(extensions.name)
    : false;

export const handleInvalidSalesSessionErrors = (...errors: Array<ApolloError | undefined>) =>
  errors.forEach((error) =>
    error?.graphQLErrors.forEach((graphqlError) => {
      if (isExpirationError(graphqlError)) {
        throw new SalesSessionError(graphqlError.message, {
          expired: true,
        });
      }
    }),
  );

export const handleSalesSessionErrors = (...errors: Array<ApolloError | undefined>) =>
  errors.forEach((error) =>
    error?.graphQLErrors.forEach((graphqlError) => {
      throw new SalesSessionError(graphqlError.message, {
        expired: isExpirationError(graphqlError),
      });
    }),
  );
