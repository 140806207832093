import {
  AvailabilityStatus,
  GroupSalesJourneyOptionFragment,
  JourneyOptionFragment,
  JourneyOptionLegFragment,
  TrainType,
} from 'backend/types.codegen';

import { abbreviations } from 'constants/stations';

import { Maybe } from 'types/utils';

export const includesTypeLegs = (
  type: TrainType,
  options: JourneyOptionFragment[] | GroupSalesJourneyOptionFragment[],
) => options.some(({ legs }) => legs?.some(({ trainType }) => trainType === type));

const KOUVOLA_KOTKA_STATIONS = new Set([
  abbreviations.Kouvola,
  abbreviations.Myllykoski,
  abbreviations.Inkeroinen,
  abbreviations.Tavastila,
  abbreviations.Kymi,
  abbreviations.Kyminlinna,
  abbreviations.Paimenportti,
  abbreviations.Kotka,
  abbreviations.KotkaHarbour,
]);

const IISALMI_YLIVIESKA_STATIONS = new Set([
  abbreviations.Iisalmi,
  abbreviations.Runni,
  abbreviations.Kiuruvesi,
  abbreviations.Pyhäsalmi,
  abbreviations.Haapajärvi,
  abbreviations.Nivala,
  abbreviations.Ylivieska,
]);

const includesStations = (stationsSet: Set<string>, stations: string[]) =>
  stations.every((station) => stationsSet.has(station));

const includesLeg = (
  stationsSet: Set<string>,
  options: JourneyOptionFragment[] | GroupSalesJourneyOptionFragment[],
) =>
  options.some((option: JourneyOptionFragment | GroupSalesJourneyOptionFragment) =>
    option?.legs?.some(
      (leg) => stationsSet.has(leg.departureStation) && stationsSet.has(leg.arrivalStation),
    ),
  );

export const includesIisalmiYlivieskaTrackStations = (stations: string[]) =>
  includesStations(IISALMI_YLIVIESKA_STATIONS, stations);

export const includesKouvolaKotkaTrackStations = (stations: string[]) =>
  includesStations(KOUVOLA_KOTKA_STATIONS, stations);

export const includesKouvolaKotkaTrackLeg = (
  options: JourneyOptionFragment[] | GroupSalesJourneyOptionFragment[],
) => includesLeg(KOUVOLA_KOTKA_STATIONS, options);

export const includesIisalmiYlivieskaLeg = (
  options: JourneyOptionFragment[] | GroupSalesJourneyOptionFragment[],
) => includesLeg(IISALMI_YLIVIESKA_STATIONS, options);
export const includeSleepingCabinLegs = (options: JourneyOptionFragment[]) => {
  return options.some(
    ({ availability: { cabinAvailability } }) => cabinAvailability !== AvailabilityStatus.None,
  );
};

export const isCommuterTrain = (trainType: Maybe<string>) =>
  trainType === TrainType.Lol || trainType === TrainType.Loc || trainType === TrainType.H;

const NoSeatTypes = new Set<string>([
  TrainType.Lol,
  TrainType.Loc,
  TrainType.H,
  TrainType.Hdm,
  TrainType.Kla,
  TrainType.Bus,
  TrainType.Hla,
  TrainType.Jla,
  TrainType.Blv,
  TrainType.Bhs,
]);

export const isTrainWithNoSeatReservation = (trainType: Maybe<string>) =>
  !!(trainType && NoSeatTypes.has(trainType));

const BusTypes = new Set<string>([
  TrainType.Kla,
  TrainType.Bus,
  TrainType.Hla,
  TrainType.Jla,
  TrainType.Blv,
  TrainType.Bhs,
]);

export const isBusLeg = (trainType: Maybe<TrainType>) => !!(trainType && BusTypes.has(trainType));

export const filterLegsWithoutSeatReservation = (option: Pick<JourneyOptionFragment, 'legs'>) => {
  return option.legs.filter((leg) => !isTrainWithNoSeatReservation(leg.trainType));
};

export const isPureBusJourney = (legs: JourneyOptionLegFragment[]) =>
  legs.every(({ trainType }) => isBusLeg(trainType));
