import { makeVar, useReactiveVar } from '@apollo/client';
import { useGetDeliveryEmail } from 'backend/hooks/useGetDeliveryEmail';
import { useCurrentPurchaseFlowStep } from 'hooks/purchaseFlow/useCurrentPurchaseFlowStep';
import { useEffect, useMemo } from 'react';
import { PurchaseFlowStepEnum } from 'types/purchaseFlow';
import { emptyArray } from 'utils/array';
import { isValidEmail } from 'utils/isValidEmail';

const storedEmailsVar = makeVar<string[] | undefined>(undefined);
const changedEmailsVar = makeVar<string[] | undefined>(undefined);

export const setStoredEmails = (emails: string[]) => {
  storedEmailsVar(emails);
};

export const setChangedEmails = (emails: string[]) => {
  changedEmailsVar(emails);
};

export const useClearEmailsOutsidePurchaseFlow = () => {
  const step = useCurrentPurchaseFlowStep();

  const clearEmails = !step || step === PurchaseFlowStepEnum.OutboundSearchResults;

  useEffect(() => {
    if (clearEmails) {
      storedEmailsVar(undefined);
      changedEmailsVar(undefined);
    }
  }, [clearEmails]);
};

export const useDeliveryEmail = () => {
  const { data: deliveryEmailQuery, loading } = useGetDeliveryEmail();
  const emailFromProfile = deliveryEmailQuery?.user.info.activeEmail;

  const storedEmails = useReactiveVar(storedEmailsVar);
  const changedEmails = useReactiveVar(changedEmailsVar);

  const emails = useMemo(() => {
    if (loading) {
      return emptyArray;
    }

    return (
      changedEmails ||
      storedEmails ||
      (emailFromProfile && [emailFromProfile]) ||
      emptyArray
    ).filter((email) => email !== '');
  }, [changedEmails, emailFromProfile, loading, storedEmails]);

  const emailDiffersFromProfile =
    !!emailFromProfile && (emails.length !== 1 || emails.at(0) !== emailFromProfile);

  const areEmailsValid = useMemo(
    () => emails.length > 0 && emails.every((email) => isValidEmail(email.toLowerCase())),
    [emails],
  );

  return {
    emails,
    areEmailsValid,
    emailDiffersFromProfile,
    loading,
  };
};

/** @knipignore */
export const TEST_clearReactiveVars = () => {
  storedEmailsVar(undefined);
  changedEmailsVar(undefined);
};
