import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useLocation } from 'utils/navigation';

import { removeInternalNotificationById, useNotifications } from 'state/notifications';
import { Notification } from '../Notification';
import { NotificationType } from '../types';

export const InternalNotifications = ({
  notificationClassName,
  inModal,
}: {
  notificationClassName?: string;
  inModal?: boolean;
}) => {
  const location = useLocation();
  const { formatMessage } = useIntl();

  const notifications = useNotifications({ inModal });

  const visibleNotifications = useMemo(
    () => notifications.filter(({ path }) => !path || path === location.pathname),
    [location.pathname, notifications],
  );

  return (
    <>
      {visibleNotifications.map(
        ({
          id,
          titleId,
          titleParams,
          bodyId,
          bodyParams,
          link,
          type,
          labelKey,
          ['data-testid']: testId,
          autoDismissAfterSeconds,
        }) => (
          <Notification
            key={id}
            id={id}
            title={(titleId && formatMessage({ id: titleId }, titleParams)) ?? ''}
            body={formatMessage({ id: bodyId }, bodyParams)}
            link={link}
            type={NotificationType[type]}
            label={labelKey && formatMessage({ id: labelKey })}
            setNotificationRead={removeInternalNotificationById}
            data-testid={testId}
            autoDismissAfterSeconds={autoDismissAfterSeconds}
            className={notificationClassName}
          />
        ),
      )}
    </>
  );
};
