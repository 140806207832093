import { LOCALE } from 'constants/i18n';
import {
  isTypeAnchorId,
  isTypeExternalLink,
  isTypeSlug,
  isTypeSlugWithParameters,
} from 'types/contentful/web';
import { EntryOfType, hasField } from 'types/contentfulUtils';
import { isContentfulEntry } from 'types/typeGuards';

import { localizePathname } from 'utils/route';
const toPathWithParams = (ref: EntryOfType<'slugWithParameters'>) => {
  if (ref.fields.anchorId && !isContentfulEntry(ref.fields.anchorId)) {
    // Link has anchor however query was not deep enough to get the entry for it
    // Fail build to identify problem
    throw new Error(
      `Could not resolve anchor link for entity ${ref.fields.title} (id: ${ref.sys.id}) - is the Contentful query deep enough (Contentful query parameter: include)?`,
    );
  }

  const pathname = ref.fields.pageSlug?.fields.pathname;
  const parameters = ref.fields.parameters ?? '';
  const hash = ref.fields.anchorId?.fields.customId
    ? `#${ref.fields.anchorId.fields.customId}`
    : '';

  return `${pathname}${parameters}${hash}`;
};

export type ButtonReference =
  | ContentfulWeb.TypeAnchorId
  | ContentfulWeb.TypeExternalLink
  | ContentfulWeb.TypeSlug
  | ContentfulWeb.TypeSlugWithParameters
  | Extract<ContentfulWeb.Entries, { fields: { title: string; pageSlug: any } }>;

export const resolveReference = (ref?: ButtonReference): { title?: string; url?: string } => {
  if (!ref || !ref.fields) {
    return {};
  }

  const locale = ref.sys.locale as LOCALE;

  if (isTypeExternalLink(ref)) {
    return {
      title: ref.fields.label,
      url: ref.fields.link ? resolveReference(ref.fields.link).url : ref.fields.urlLong,
    };
  }

  if (isTypeSlugWithParameters(ref)) {
    return {
      title: ref.fields.title,
      url: localizePathname(locale, toPathWithParams(ref)),
    };
  }

  if (isTypeSlug(ref)) {
    return {
      title: ref.fields.title,
      url: localizePathname(locale, ref.fields.pathname),
    };
  }

  if (isTypeAnchorId(ref)) {
    return {
      url: `#${ref.fields.customId}`,
    };
  }

  if (hasField(ref, 'pageSlug') && !!ref.fields.pageSlug) {
    return {
      title: ref.fields.title,
      url: localizePathname(locale, ref.fields.pageSlug.fields.pathname),
    };
  }

  return {};
};

export const parseContentfulLinkReference = (
  ref?: Array<ButtonReference | undefined> | ButtonReference,
): { title?: string; url?: string } => {
  if (!ref || (Array.isArray(ref) && !ref[0])) {
    return {};
  }

  return resolveReference(Array.isArray(ref) ? ref[0] : ref);
};
