export const abbreviations = {
  Espoo: 'EPO',
  Hameenlinna: 'HL',
  Haapajärvi: 'HPJ',
  Helsinki: 'HKI',
  Iisalmi: 'ILM',
  Inkeroinen: 'IKR',
  Karjaa: 'KR',
  Kemi: 'KEM',
  Kemijärvi: 'KJÄ',
  Kiuruvesi: 'KRV',
  Kolari: 'KLI',
  Kotka: 'KTA',
  KotkaHarbour: 'KTS',
  Kouvola: 'KV',
  Kupittaa: 'KUT',
  Kymi: 'KY',
  Kyminlinna: 'KLN',
  Lahti: 'LH',
  Lappeenranta: 'LR',
  Mikkeli: 'MI',
  MoskvaLeningradski: 'MVA',
  Myllykoski: 'MKI',
  Nivala: 'NVL',
  Oulu: 'OL',
  Paimenportti: 'PTI',
  Pasila: 'PSL',
  PasilaVehicleLoading: 'PAU',
  Pyhäsalmi: 'PHÄ',
  Riihimaki: 'RI',
  Rovaniemi: 'ROI',
  Runni: 'RNN',
  Salo: 'SLO',
  Seinajoki: 'SK',
  StPetersburgFinljandski: 'PTR',
  StPetersburgLadozhki: 'PTL',
  Tampere: 'TPE',
  Tavastila: 'TSL',
  Tikkurila: 'TKL',
  Toijala: 'TL',
  Turku: 'TKU',
  TurkuHarbour: 'TUS',
  Tver: 'TVE',
  Vainikkala: 'VNA',
  Vyborg: 'VYB',
  Ylivieska: 'YV',
};

export enum StationCountry {
  FINLAND = 10,
}

export const AllegroStations = [
  abbreviations.Vainikkala,
  abbreviations.Vyborg,
  abbreviations.StPetersburgFinljandski,
] as const;

export const TolstoiStations = [
  abbreviations.Vyborg,
  abbreviations.StPetersburgLadozhki,
  abbreviations.Tver,
  abbreviations.MoskvaLeningradski,
] as const;

export const GroupJourneyNightTrainStations = [
  abbreviations.Kolari,
  abbreviations.Rovaniemi,
  abbreviations.Kemijärvi,
  abbreviations.Kemi,
  abbreviations.Oulu,
] as const;

export const VIA_STATIONS: readonly string[] = ['LEN'];
