import Image from 'next/image';

import { Text } from 'components/cssModules';

import type { ContentfulNodeProps } from '../RichText.types';
import styles from './EmbeddedAsset.module.css';

export const EmbeddedAsset = ({ node: { data } }: ContentfulNodeProps) => {
  if (!data.target) {
    return null;
  }
  const {
    target: { fields, sys },
  } = data;
  const contentType = sys.type;
  const description = fields.description;
  const assetType = fields.file.contentType;
  const url = fields.file.url;

  if (assetType === 'application/pdf' && description && url) {
    return (
      <Text paragraph={18}>
        <a href={url} rel="noreferrer noopener" target="_blank">
          {description}
        </a>
      </Text>
    );
  }

  if (contentType === 'Asset' && url) {
    return (
      <figure className={styles.wrapper} aria-hidden={!description}>
        <div className={styles.container}>
          <Image
            src={url}
            alt=""
            {...fields.file.details.image}
            // Matches container max width
            sizes={'60rem'}
          />
        </div>
        <Text size={16} as="figcaption" semibold grey17>
          {description}
        </Text>
      </figure>
    );
  }

  return null;
};
