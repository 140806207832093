import { useIdentity } from './useIdentity';

export const useIsEmployeeUser = () => {
  const [identity] = useIdentity();
  return !!identity?.isEmployee;
};

export const useIsVREmployeeUser = () => {
  const [identity] = useIdentity();
  return !!identity?.isVrEmployee;
};

export const useIsPlEmployeeUser = () => {
  const [identity] = useIdentity();
  return !!identity?.isPlEmployee;
};

export const useIsTramEmployeeUser = () => {
  const [identity] = useIdentity();
  return !!identity?.isTramEmployee;
};
