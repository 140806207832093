/**
 * @knipignore knip doesn't handle Object.values
 */
export enum ProfilePages {
  CHANGE_YOUR_PASSWORD_PAGE = 'changeYourPasswordPage',
  MY_JOURNEYS_PAGE = 'myJourneysPage',
  JOURNEY_PAGE = 'journeyPage',
  MULTI_TICKET_PAGE = 'multiTicketPage',
  PROFILE_PAGE = 'profilePage',
  MY_PURCHASE_HISTORY_PAGE = 'myPurchaseHistoryPage',
  CANCEL_JOURNEY_PAGE = 'cancelJourneyPage',
  CANCEL_GROUP_JOURNEY_PAGE = 'cancelGroupJourneyPage',
  SELECT_JOURNEYS_TO_CANCEL_PAGE = 'selectJourneysToCancelPage',
  SELECT_VEHICLES_TO_CANCEL_PAGE = 'selectVehiclesToCancelPage',
  JOURNEY_CHANGES_PAGE = 'journeyChangesPage',
  JOURNEY_RECEIPT_PAGE = 'journeyReceiptPage',
  JOURNEY_SEARCH_PAGE = 'journeySearchPage',
  JOURNEY_DETAILS_PAGE = 'journeyDetailsPage',
  PRE_ORDER_BREAKFAST_PAGE = 'preOrderBreakfastPage',
  PAYMENT_PAGE = 'paymentPage',
  PURCHASE_SUMMARY_PAGE = 'purchaseSummaryPage',
  EDIT_PAYMENT_REFERENCES = 'editPaymentReferences',
  GROUP_SALES_PAYMENT_PAGE = 'groupSalesPaymentPage',
  GROUP_SALES_ADD_MEALS_PAGE = 'groupSalesAddMealsPage',
}

export enum FormPages {
  CORPORATE_INVITE_FORM = 'corporateInviteForm',
  GENERAL_FEEDBACK_FORM = 'generalFeedbackForm',
  DATA_PROTECTION = 'dataProtectionRequestForm',
  FAILED_PURCHASE = 'failedPurchaseForm',
  GROUP_SALES_FORM = 'groupSalesForm',
  TRAIN_DELAY_COMPENSATION = 'trainDelayCompensationForm',
  OTHER_COMPENSATION = 'otherCompensationForm',
  TICKET_CANCELLATION_FORM = 'ticketCancellationForm',
}
