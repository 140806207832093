import { gql } from '@apollo/client';

import {
  AvecraServicesFragment,
  CancellationInsuranceFragment,
  CorporateOfferFragment,
  DiscountCodeFragment,
  ExternalServiceFragment,
  JourneyFeeFragment,
  LegReservationFragment,
  PriceElementFragment,
  SummaryFragment,
  TrainInfoFragment,
  VehicleFragment,
} from 'backend/fragments/backend-fragments';

export const SalesSessionFragment = gql`
  ${VehicleFragment}
  ${AvecraServicesFragment}
  ${SummaryFragment}
  ${JourneyFeeFragment}
  ${TrainInfoFragment}
  ${LegReservationFragment}
  ${CancellationInsuranceFragment}
  ${PriceElementFragment}
  ${ExternalServiceFragment}
  ${CorporateOfferFragment}
  ${DiscountCodeFragment}

  fragment SeriesTicket on SeriesTicket {
    type
    passengerJourneyId
    stations
    usageStartTime
    usageEndTime
    ticketCount
    periodLengthDays
    price
    ticketClass
  }

  fragment SessionJourneyLeg on JourneyLeg {
    id
    arrivalTime
    arrivalStation
    departureTime
    departureStation
    legKey
    trafficType
    trainType
    commercialTrainNumber
    reservation {
      ...LegReservation
    }

    additionalReservations {
      ...LegReservation
    }

    avecraServices {
      ...AvecraServices
    }

    bicycles {
      wagonNumber
    }

    trainInfo {
      ...TrainInfo
    }
  }

  fragment SessionPassengerJourney on PassengerJourney {
    id
    seriesTicketId

    passenger {
      type
      eligibleForCancellationInsurance
      linkedToPassenger
    }

    bicycleFees {
      ...JourneyFee
    }

    petFees {
      ...JourneyFee
    }

    otherFees {
      ...JourneyFee
    }

    legs {
      ...SessionJourneyLeg
    }

    cancellationInsuranceOffer {
      ...CancellationInsurance
    }

    externalServiceOffers {
      ...ExternalService
    }

    discountCodes {
      ...DiscountCode
    }
  }

  fragment SessionGroupJourney on GroupJourney {
    groupId
    idWithDepartureStation
    departureStation
    departureTime
    arrivalStation
    arrivalTime
    usageEndTime
    usageStartTime
    trafficType
    corporate {
      ...CorporateOffer
    }

    vehicles {
      ...Vehicle
    }

    cabins {
      id
      legKey
      type
      attribute
      size
      totalPrice
      passengers {
        passengerLegId
        type
        reservation {
          id
          placeNumber
          wagonNumber
          compartmentNumber
          position
          attribute
          floor
          validUntil
          cabinId
        }
      }
    }

    additionalProducts {
      type
      count
    }

    passengerJourneys {
      ...SessionPassengerJourney
    }

    summary {
      ...Summary
    }
  }

  fragment SalesSession on SalesSession {
    id
    orderNumber
    groupJourneys {
      ...SessionGroupJourney
    }
    sessionSummary {
      ...Summary
    }
    seriesTicket {
      ...SeriesTicket
    }
    validUntil
    amountToPay
    price {
      amount
      elements {
        ...PriceElement
      }
    }
    onBehalfOf
    externalServicePaymentStartDeadline
  }
`;
