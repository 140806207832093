import { gql, useQuery } from '@apollo/client';

import { GetSalesSessionQuery, GetSalesSessionQueryVariables } from 'backend/types.codegen';
import { handleSalesSessionErrors } from 'backend/utils/salesSessionErrors';

import { Maybe } from 'types/utils';

import { SalesSessionFragment } from './fragments';

export const GET_SALES_SESSION = gql`
  ${SalesSessionFragment}

  query getSalesSession($sessionId: ID!) {
    salesSession(id: $sessionId) {
      ...SalesSession
    }
  }
`;

const validateQueryResult = (data: GetSalesSessionQuery | undefined) => {
  return (data?.salesSession?.groupJourneys?.length || 0) > 0 || data?.salesSession?.seriesTicket;
};

interface Params {
  throwOnError?: boolean;
}

export const useSalesSession = (sessionId: Maybe<string>, { throwOnError = true }: Params = {}) => {
  const response = useQuery<GetSalesSessionQuery, GetSalesSessionQueryVariables>(
    GET_SALES_SESSION,
    {
      fetchPolicy: 'cache-first',
      ssr: false,
      skip: !sessionId,
      variables: sessionId ? { sessionId: sessionId } : undefined,
    },
  );

  if (throwOnError && response.error) {
    handleSalesSessionErrors(response.error);
  }

  const validated = {
    ...response,
    data: validateQueryResult(response.data) ? response.data : undefined,
  };

  return validated;
};
