'use client';
import Image, { ImageProps } from 'next/image';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { LOCALE } from 'constants/i18n';
import { FrontPageMessage, LocalizationKey } from 'constants/i18n.messages';

type Badge = 'AppStore' | 'GooglePlay';

interface Props extends Omit<ImageProps, 'src' | 'height' | 'width' | 'alt'> {
  badge: Badge;
}

const height = 40;

const altIds: Record<Badge, LocalizationKey> = {
  AppStore: FrontPageMessage.APP_STORE_LINK,
  GooglePlay: FrontPageMessage.PLAY_STORE_LINK,
};

const badges: Record<LOCALE, Record<Badge, { src: string; width: number }>> = {
  en: {
    AppStore: {
      src: '/images/svg/icons/AppStoreEn.svg',
      width: 120,
    },
    GooglePlay: {
      src: '/images/svg/icons/GooglePlayEn.svg',
      width: 135,
    },
  },
  fi: {
    AppStore: {
      src: '/images/svg/icons/AppStoreFi.svg',
      width: 143,
    },
    GooglePlay: {
      src: '/images/svg/icons/GooglePlayFi.svg',
      width: 135,
    },
  },
  sv: {
    AppStore: {
      src: '/images/svg/icons/AppStoreSv.svg',
      width: 120,
    },
    GooglePlay: {
      src: '/images/svg/icons/GooglePlaySv.svg',
      width: 135,
    },
  },
};

export function StoreBadge({ badge, ...props }: Props) {
  const { locale, formatMessage } = useIntl();

  const { src, width } = badges[locale][badge];
  const alt = useMemo(() => formatMessage({ id: altIds[badge] }), [formatMessage, badge]);

  return <Image unoptimized {...props} alt={alt} src={src} width={width} height={height} />;
}
