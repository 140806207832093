import { useEffect, useState } from 'react';

import { SLICE, Slice, toEm } from '@vrfi/web-components';

const useMediaQuery = (mediaQuery: string) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const query = window.matchMedia(mediaQuery);
    setMatches(query.matches);

    const onChange = (event: MediaQueryListEvent) => {
      setMatches(event.matches);
    };

    query.addEventListener('change', onChange);

    return () => {
      query.removeEventListener('change', onChange);
    };
  }, [mediaQuery]);

  return matches;
};

export const useIsToSlice = (slice: Slice) =>
  useMediaQuery(`(max-width: ${toEm(SLICE[slice].to)})`);

export const useIsFromSlice = (slice: Slice) =>
  useMediaQuery(`(min-width: ${toEm(SLICE[slice].from)})`);
