'use client';
import produce from 'immer';
import {
  Dispatch,
  ReactNode,
  Reducer,
  createContext,
  useContext,
  useMemo,
  useReducer,
} from 'react';

import { RouteState } from './types';

const initialState: RouteState = {
  redirectToLandingPageOnLogout: false,
};

const routeReducer: Reducer<RouteState, Partial<RouteState>> = (state, payload) => {
  return produce(state, (state) => ({ ...state, ...payload }));
};

type RouteContext = [RouteState, Dispatch<Partial<RouteState>>];

const RouteStateContext = createContext<RouteContext | undefined>(undefined);

RouteStateContext.displayName = 'RouteState';

export const RouteProvider = ({
  redirectToLandingPageOnLogout = false,
  children,
}: {
  redirectToLandingPageOnLogout?: boolean;
  children: ReactNode;
}) => {
  const [state, updatePartialState] = useReducer(routeReducer, {
    ...initialState,
    redirectToLandingPageOnLogout,
  });
  const value: RouteContext = useMemo(() => [state, updatePartialState], [state]);

  return <RouteStateContext.Provider value={value}>{children}</RouteStateContext.Provider>;
};

export const useRouteState = () => {
  const context = useContext(RouteStateContext);
  if (context === undefined) {
    throw new Error('useRouteState must be used within a RouteStateProvider');
  }
  return context;
};
