import Image from 'next/image';
import { memo } from 'react';

import { ContentComponentProps } from 'types/contentComponent';

const ContentfulLinkWithImage = (props: ContentComponentProps<'linkWithImage'>) => {
  const { image, label, link } = props.content.fields;

  return (
    image?.fields.file && (
      <a href={link} aria-label={label} data-testid="contentful-link-with-image">
        <Image src={image.fields.file.url} alt={label} {...image.fields.file.details.image} />
      </a>
    )
  );
};
export default memo(ContentfulLinkWithImage);
