import { v4 } from 'uuid';

import { isSSR } from 'utils/isSSR';

import type { SetToasts, Toast } from './Toast.types';

const toasts: {
  queue: Toast[];
  setToasts?: SetToasts;
} = { queue: [] };

function addToast(toast: Omit<Toast, 'id'>) {
  if (toasts.setToasts) {
    toasts.setToasts((toasts) => [...toasts, { ...toast, id: v4() }]);
  } else {
    toasts.queue.push({ ...toast, id: v4() });
  }
}

declare global {
  interface Window {
    _addToast: typeof addToast;
    _setElementVisibility: typeof setElementVisibility;
  }
}

export function exposeToastToGlobal(setToasts: SetToasts) {
  if (toasts.setToasts === setToasts) return;
  toasts.setToasts = setToasts;
}

export function getToastsInQueue() {
  const toastQueue = toasts.queue.slice(0);
  toasts.queue.length = 0;
  return toastQueue;
}

const elements: {
  setElementVisibility?: React.Dispatch<React.SetStateAction<ElementVisibilityRules>>;
  elementVisibilities: ElementVisibilityRules;
} = {
  elementVisibilities: {},
};

function setElementVisibility(id: string, show: boolean) {
  if (elements.setElementVisibility) {
    elements.setElementVisibility((visibilities) => ({ ...visibilities, [id]: show }));
  }
}

export type ElementVisibilityRules = { [key in string]: boolean };

export function exposeElementVisibilityToGlobal(
  setElementVisibility: React.Dispatch<React.SetStateAction<ElementVisibilityRules>>,
) {
  if (elements.setElementVisibility === setElementVisibility) return;
  elements.setElementVisibility = setElementVisibility;
}

if (!isSSR()) {
  window._addToast = addToast;
  window._setElementVisibility = setElementVisibility;
}
