import { Asset, AssetDetails, Entry } from 'contentful';

type ContentfulEntry = ContentfulWeb.Entries | ContentfulShared.Entries;

export type GetContentType<T extends ContentfulEntry> = T['sys']['contentType']['sys']['id'];

export type ContentType = GetContentType<ContentfulEntry>;

type HasContentType<T extends ContentType> = { sys: { contentType: { sys: { id: T } } } };

export type EntryOfType<T extends ContentType> = Extract<ContentfulEntry, HasContentType<T>>;

export type SkeletonOfType<T extends ContentType> = Extract<
  ContentfulWeb.Skeletons | ContentfulShared.Skeletons,
  { contentTypeId: T }
>;

const getContentType = <T extends ContentfulEntry>(entry: T): GetContentType<T> | undefined =>
  entry.sys.contentType?.sys.id;

export const isContentType =
  <C extends ContentType>(contentType: C) =>
  (entry?: Entry<any>): entry is EntryOfType<C> =>
    entry ? getContentType(entry) === contentType : false;

export const hasField = <T extends ContentfulEntry, Field extends string>(
  entry: T,
  field: Field,
): entry is Extract<T, { fields: { [key in Field]: any } }> => field in entry.fields;

export const getImageProps = (asset: Asset | undefined) => {
  const details = asset?.fields.file ? (asset.fields.file.details as AssetDetails) : null;

  if (!asset?.fields.file || typeof asset?.fields.file.url !== 'string' || !details?.image) {
    return undefined;
  }

  return {
    src: asset.fields.file.url,
    width: details.image.width,
    height: details.image.height,
  };
};
