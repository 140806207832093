import type { ReactNode } from 'react';

import type { CmLoadingProps } from '@vrfi/web-components';

import styles from './ButtonLoading.module.css';
import { Loading } from './Loading';

interface Props extends CmLoadingProps {
  children: ReactNode;
  loading?: boolean;
}

/** Parent of this component must be a CSS Modules Button to guarantee correct default loading indicator color. */
export const ButtonLoading = ({ children, loading, ...props }: Props) => {
  if (!loading) {
    return <>{children}</>;
  }

  return (
    <>
      <div className={styles.content}>{children}</div>
      <Loading
        size={3}
        animation="bars"
        aria-live="polite"
        className={styles.indicator}
        {...props}
      />
    </>
  );
};
