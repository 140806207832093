import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";

export interface TypeSlugFields {
    title: EntryFieldTypes.Symbol;
    slug?: EntryFieldTypes.Symbol;
    pathname: EntryFieldTypes.Symbol;
    removeFromIndex?: EntryFieldTypes.Boolean;
}

export type TypeSlugSkeleton = EntrySkeletonType<TypeSlugFields, "slug">;
export type TypeSlug<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeSlugSkeleton, Modifiers, Locales>;

export function isTypeSlug<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeSlug<Modifiers, Locales> {
    return entry.sys.contentType.sys.id === 'slug'
}
