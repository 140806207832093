import { OrderGroupJourneyFragment, SessionGroupJourneyFragment } from 'backend/types.codegen';

import { CancelFlowSource, CancellationFlow } from 'hooks/useCancellationQueryParams';

export interface FlowStep {
  path: string;
}

interface OrderQueryParams {
  orderNumber?: string;
  referenceNumber?: string;
  journeyKey: string | undefined;
}

export type CancellationQueryParams = OrderQueryParams & {
  sp: string[];
  sv: string[];
  gj?: string;
  referenceNumber?: string;
  source: CancelFlowSource;
  flow?: CancellationFlow;
};

export type PageVariant = 'light' | 'dark';

export const enum DiscountCodeType {
  FDF = 'FDF',
  // Personal = 'PERSONAL',
}

export const enum DiscountCodeUsageType {
  SingleUse = 'SINGLE_USE',
  MultiUse = 'MULTI_USE',
}

export type AnyGroupJourney = SessionGroupJourneyFragment | OrderGroupJourneyFragment;
