import classNames from 'classnames';
import {
  HTMLAttributes,
  KeyboardEventHandler,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useIntl } from 'react-intl';

import { ColorEnum } from '@vrfi/web-components';

import { Button } from '@vrfi/web-components';
import { Box } from 'components/cssModules';
import { CancelIcon } from 'components/ui/Icons';

import { GenericMessage } from 'constants/i18n.messages';

import { useVariable } from 'hooks/useVariable';

import { useCookieConsent } from 'hooks/useCookieConsent';
import { InternalNotifications } from '../Notifications/InternalNotifications';
import { Portal } from '../Portal/Portal';
import { RemoveScroll } from '../RemoveScroll/RemoveScroll';
import styles from './Modal.module.css';
import { polyfillDialog } from './Modal.polyfill';

interface Props<T> extends Omit<HTMLAttributes<HTMLDialogElement>, 'children'> {
  ['data-testid']?: string;
  cancelColor?: ColorEnum;
  footerContent?: ReactNode;
  headerContent?: ReactNode | string | null;
  heroContent?: ReactNode;
  onClose?: (value?: T) => void;
  size?: 'lg' | 'md' | 'sm' | 'tiny';
  className?: string;
  children: (props: { onClose: (value?: T) => void }) => ReactNode;
  skipCookieConsentCheck?: boolean;
}

const modalTitleId = 'modal-title';

export function Modal<T>({
  cancelColor = 'var(--color-green10)',
  children,
  footerContent,
  headerContent,
  heroContent,
  onClose: onCloseProp,
  size,
  className,
  skipCookieConsentCheck,
  ...props
}: Props<T>) {
  const [{ consented }] = useCookieConsent();
  const { formatMessage } = useIntl();
  const dialogRef = useRef<HTMLDialogElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const onClosePropRef = useVariable(onCloseProp);

  useEffect(() => {
    const dialog = dialogRef.current;
    if (
      !dialog ||
      (!consented && !skipCookieConsentCheck) // do not open dialogs if cookies have not been consented. The cookie consent modal would appear behind this modal
    ) {
      return;
    }

    polyfillDialog(dialog, (dialog) => {
      dialog.showModal();
      titleRef.current?.focus();
    });
  }, [consented, skipCookieConsentCheck]);

  const onClose = useCallback(
    (value?: T) => {
      dialogRef.current?.close();
      onClosePropRef.current?.(value);
    },
    [onClosePropRef],
  );

  const onKeyDown: KeyboardEventHandler<HTMLDialogElement> = (event) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      event.stopPropagation();
      onClose();
    }
  };

  const onClick: MouseEventHandler<HTMLDialogElement> = (event) => {
    if (event.target === dialogRef.current) {
      onClose();
    }
  };

  return (
    <Portal>
      <RemoveScroll>
        <Box color="white" spacing="s00Column" variant="card">
          <dialog
            ref={dialogRef}
            className={classNames(styles.dialog, styles[`size_${size}`], className)}
            aria-modal
            role="dialog"
            onClick={onClick}
            onKeyDown={onKeyDown}
            {...props}
          >
            <div
              data-box={`${size === 'tiny' ? 's24' : 's32'} column`}
              data-gap={props['data-gap'] ?? 's24'}
              className={styles.mainContent}
            >
              <InternalNotifications notificationClassName={styles.notification} inModal />

              {heroContent}

              {!!headerContent && (
                <header className={styles.header}>
                  <h1
                    data-text="heading4"
                    id={modalTitleId}
                    data-testid="title"
                    ref={titleRef}
                    tabIndex={-1}
                  >
                    {headerContent}
                  </h1>

                  <Button
                    circle
                    aria-label={formatMessage({ id: GenericMessage.CLOSE })}
                    data-testid="modal-close-button"
                    onClick={() => onClose()}
                    className={styles.closeButton}
                  >
                    <CancelIcon color={cancelColor} size={2} />
                  </Button>
                </header>
              )}

              {children({ onClose })}

              {footerContent}
            </div>
          </dialog>
        </Box>
      </RemoveScroll>
    </Portal>
  );
}
