import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";
import type { TypeSlugSkeleton } from "./TypeSlug";
import type { TypeSlugWithParametersSkeleton } from "./TypeSlugWithParameters";

export interface TypeExternalLinkFields {
    name: EntryFieldTypes.Symbol;
    id?: EntryFieldTypes.Symbol;
    label?: EntryFieldTypes.Symbol;
    link?: EntryFieldTypes.EntryLink<TypeSlugSkeleton> | EntryFieldTypes.EntryLink<TypeSlugWithParametersSkeleton>;
    urlLong?: EntryFieldTypes.Text;
}

export type TypeExternalLinkSkeleton = EntrySkeletonType<TypeExternalLinkFields, "externalLink">;
export type TypeExternalLink<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeExternalLinkSkeleton, Modifiers, Locales>;

export function isTypeExternalLink<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeExternalLink<Modifiers, Locales> {
    return entry.sys.contentType.sys.id === 'externalLink'
}
