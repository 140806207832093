import { NodeRenderer, documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import classNames from 'classnames';
import { Children, memo } from 'react';

import Link from 'components/ui/Link';

import { parseContentfulLinkReference } from 'content/restContent';

import { isStringArray } from 'types/typeGuards';

import { EmbeddedAsset } from './EmbeddedAsset/EmbeddedAsset';
import { EmbeddedEntry } from './EmbeddedEntry/EmbeddedEntry';
import { EmbeddedEntryInline } from './EmbeddedEntryInline/EmbeddedEntryInline';
import styles from './RichText.module.css';
import type {
  AllProps,
  Options,
  ParagraphToStrings,
  RenderMark,
  RenderNode,
  RenderText,
} from './RichText.types';
import { createRendererWithNode } from './utils/createRenderer';

const Bold = (text: React.ReactNode) => <strong>{text}</strong>;
const Italic = (text: React.ReactNode) => <em>{text}</em>;
const Underline = (text: React.ReactNode) => <u>{text}</u>;

const renderMark: RenderMark = {
  [MARKS.BOLD]: Bold,
  [MARKS.ITALIC]: Italic,
  [MARKS.UNDERLINE]: Underline,
};

const renderText: RenderText = (text) => text.replace(/&shy;/gi, '\u00AD');

const paragraphsToStrings: ParagraphToStrings = (node, children) => {
  if (node.content.some((item) => item.nodeType !== 'paragraph')) {
    return children;
  }
  return Children.map(
    children,
    (paragraph) =>
      paragraph && (
        <>
          {paragraph.props.children}
          <br />
        </>
      ),
  );
};

const filterEmptyContent =
  (renderer: NodeRenderer): NodeRenderer =>
  (node, children) => {
    if (isStringArray(children) && !children.join('').trim()) {
      return null;
    }
    return renderer(node, children);
  };

const RichText = ({ document, textSize = 'md', openLinksInNewTab }: AllProps) => {
  if (!document) return null;

  const className = textSize === 'lg' ? styles.paragraph18 : styles.paragraph16;

  const renderNode: RenderNode = {
    [INLINES.ENTRY_HYPERLINK]: function FrameworkLink({ data }, children) {
      const { url } = parseContentfulLinkReference(data.target);
      if (!url) return null;

      return (
        <Link
          href={url}
          {...(openLinksInNewTab && {
            rel: 'noreferrer noopener',
            target: '_blank',
          })}
        >
          {children}
        </Link>
      );
    },
    [INLINES.HYPERLINK]: function Hyperlink({ data }, children) {
      if (!data.uri) return null;

      if (data.uri.startsWith('mailto:') || data.uri.startsWith('tel:')) {
        return <a href={data.uri}>{children}</a>;
      }

      const firstChildren =
        Array.isArray(children) && typeof children[0] === 'string' ? children[0] : null;

      const hasLink = !!(firstChildren && firstChildren.match(/^https?:\/\/|^www\./));

      return (
        <Link
          href={data.uri.toString()}
          {...(openLinksInNewTab && {
            rel: 'noreferrer noopener',
            target: '_blank',
          })}
          className={classNames({ [styles.linkWithoutLabel]: hasLink })}
        >
          {children}
        </Link>
      );
    },
    [INLINES.ASSET_HYPERLINK]: function AssetHyperlink({ data }, children) {
      if (!data.target.fields.file.url) return null;

      return (
        <Link
          href={`https:${data.target.fields.file.url}`}
          {...(openLinksInNewTab && {
            rel: 'noreferrer noopener',
            target: '_blank',
          })}
        >
          {children}
        </Link>
      );
    },
    [INLINES.EMBEDDED_ENTRY]: createRendererWithNode(EmbeddedEntryInline),
    [BLOCKS.EMBEDDED_ENTRY]: createRendererWithNode(EmbeddedEntry),
    [BLOCKS.EMBEDDED_ASSET]: createRendererWithNode(EmbeddedAsset),
    [BLOCKS.QUOTE]: filterEmptyContent((_, children) => (
      <blockquote className={styles.blockquote}>{children}</blockquote>
    )),
    [BLOCKS.HEADING_2]: filterEmptyContent((_, children) => (
      <h2 className={styles.title2}>{children}</h2>
    )),
    [BLOCKS.HEADING_3]: filterEmptyContent((_, children) => (
      <h3 className={styles.title3}>{children}</h3>
    )),
    [BLOCKS.HEADING_4]: filterEmptyContent((_, children) => (
      <h4 className={styles.title4}>{children}</h4>
    )),
    [BLOCKS.HEADING_5]: filterEmptyContent((_, children) => (
      <h5 className={styles.title5}>{children}</h5>
    )),
    [BLOCKS.HEADING_6]: filterEmptyContent((_, children) => (
      <h6 className={styles.title6}>{children}</h6>
    )),
    [BLOCKS.LIST_ITEM]: function ListItem(node, children) {
      return <li>{paragraphsToStrings(node, children as JSX.Element)}</li>;
    },
    [BLOCKS.OL_LIST]: (_, children) => <ol className={className}>{children}</ol>,
    [BLOCKS.UL_LIST]: (_, children) => <ul className={className}>{children}</ul>,
    [BLOCKS.PARAGRAPH]: filterEmptyContent((_, children) => (
      <p className={className}>{children}</p>
    )),
  };

  const options: Options = {
    renderText,
    renderMark,
    renderNode,
  };

  return <>{documentToReactComponents(document, options)}</>;
};

export default memo(RichText);
