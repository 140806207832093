import { toASCII, toUnicode } from 'punycode';
import tlds from 'tlds';

/**
 * We're trying to match commons EmailValidator not to validate emails
 *
 * https://commons.apache.org/proper/commons-validator/xref/org/apache/commons/validator/routines/EmailValidator.html
 */
const SPECIAL_CHARS = '\\(\\)<>@,;:\'\\\\\\"\\.\\[\\]';
const VALID_CHARS = '(\\\\.)|[^\\s' + SPECIAL_CHARS + ']';
const QUOTED_USER = '("(\\\\"|[^"])*")';
const WORD = '((' + VALID_CHARS + "|')+|" + QUOTED_USER + ')';
const DOMAIN_LABEL = '[a-zA-Z0-9](?:[a-zA-Z0-9\\-]{0,61}[a-zA-Z0-9])?';
const TOP_LABEL = '[a-zA-Z](?:[a-zA-Z0-9\\-]{0,61}[a-zA-Z0-9])?';

const EMAIL_PATTERN = new RegExp('^(.+)@(\\S+)$');
const IP_DOMAIN_PATTERN = new RegExp('^\\[(.*)\\]$');
const USER_PATTERN = new RegExp('^' + WORD + '(\\.' + WORD + ')*$');
const DOMAIN_PATTERN = new RegExp('^(?:' + DOMAIN_LABEL + '\\.)*(' + TOP_LABEL + ')\\.?$');

const MAX_USERNAME_LEN = 64;

const hasValidTLD = (tld: string) => {
  return tld && tlds.includes(toUnicode(tld));
};

const isValidDomain = (domain: string) => {
  if (!domain) {
    return false;
  }

  const domainAsASCII = toASCII(domain);
  if (domainAsASCII.length > 253) {
    return false;
  }

  if (domainAsASCII.match(IP_DOMAIN_PATTERN)) {
    return true;
  }
  const groups = domainAsASCII.match(DOMAIN_PATTERN);

  if (groups && groups.length > 1) {
    return hasValidTLD(groups[1]) && groups[0] !== groups[1];
  }

  return false;
};

const isValidUser = (user: string | undefined) => {
  if (!user || user.length > MAX_USERNAME_LEN) {
    return false;
  }

  return user.match(USER_PATTERN);
};

export const isValidEmail = (email: string | null | undefined) => {
  if (!email || email.endsWith('.')) {
    return false;
  }

  const groups = email.match(EMAIL_PATTERN);
  if (!groups || !isValidUser(groups[1]) || !isValidDomain(groups[2])) {
    return false;
  }

  return true;
};
