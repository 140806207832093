import { LineItemProduct } from '@vrfi/payment-summary';

import {
  AdditionalProduct,
  GroupJourney,
  OrderGroupJourneyFragment,
  OrderPassengerJourneyFragment,
  PassengerType,
  ProductType,
  SessionGroupJourneyFragment,
} from 'backend/types.codegen';

import { BED_CABIN_TYPES } from 'constants/journey';

import { DiscountCodeType } from 'types/types';
import { Maybe } from 'types/utils';

import { isNonNullable, isUniq } from './array';
import { isCommuterTrain, isTrainWithNoSeatReservation } from './connection';
import { comparePlainDate, createPlainDate, plainDateFromUnknown, subDays } from './plainDate';

/*
 * !!! IMPORTANT !!!
 * These mappers are not perfect, they only work for use cases they are currently used by. Always check that they bring all data you need before use
 */
export const getAdditionalProductsFromPassengerJourney = (
  passengerJourney: OrderPassengerJourneyFragment,
): AdditionalProduct[] => {
  const additionalProducts: AdditionalProduct[] = [];

  // Pet fees
  if (passengerJourney && passengerJourney.petFees && passengerJourney.petFees.length > 0) {
    additionalProducts.push({
      type: ProductType.Pet,
      count: passengerJourney.petFees.length,
    });
  }

  // Bicycles
  if (passengerJourney && passengerJourney.bicycleFees && passengerJourney.bicycleFees.length > 0) {
    additionalProducts.push({
      type: ProductType.Bicycle,
      count: passengerJourney?.bicycleFees?.length ?? 0,
    });
  }

  // Cancellation insurance
  if (passengerJourney.cancellationInsurance) {
    additionalProducts.push({
      type: ProductType.CancellationInsuranceJourney,
      count: 1,
    });
  }

  // TODO: Vehicle Cancellation Insurance
  return additionalProducts;
};

export const vehicleCancellationPeriodEnded = (groupJourney: OrderGroupJourneyFragment) => {
  // Vehicle CancellationInsurance.validUntil is always null, so let's calculate manually
  const departureDate = plainDateFromUnknown(groupJourney.departureTime);
  const validUntil = subDays(departureDate, 3);
  return validUntil && comparePlainDate(validUntil, createPlainDate()) < 0;
};

export const hasVehiclesWithCancellationInsurance = (groupJourney: OrderGroupJourneyFragment) => {
  if (!groupJourney.vehicles || groupJourney.vehicles.length === 0) return false;

  const vehicleWithCancellationInsurance = groupJourney.vehicles?.find(
    (vehicle) => vehicle.cancellationInsurance,
  );
  return !!vehicleWithCancellationInsurance;
};

export const hasPassengerJourneysWithCancellationInsurance = (
  groupJourney: OrderGroupJourneyFragment,
) => {
  return (groupJourney.passengerJourneys || []).some((pj) => pj.cancellationInsurance != null);
};

export const hasSomeCancellationInsurance = (groupJourney: OrderGroupJourneyFragment) => {
  return (
    hasVehiclesWithCancellationInsurance(groupJourney) ||
    hasPassengerJourneysWithCancellationInsurance(groupJourney)
  );
};

export const getFirstVehicle = (
  groupJourney: SessionGroupJourneyFragment | GroupJourney | OrderGroupJourneyFragment,
) => {
  if (groupJourney && groupJourney.vehicles && groupJourney.vehicles.length > 0) {
    return groupJourney.vehicles[0];
  }

  return undefined;
};

export const isSeriesTicketUsage = (groupJourney: OrderGroupJourneyFragment) =>
  groupJourney.passengerJourneys?.some((passengerJourney) => passengerJourney.seriesTicketId) ??
  false;

export const isSeasonTicketUsage = (groupJourney: OrderGroupJourneyFragment) =>
  groupJourney.passengerJourneys?.some((passengerJourney) => passengerJourney.seasonTicketId) ??
  false;

export const getLegServicesForGroupJourney = (groupJourney: SessionGroupJourneyFragment) => {
  const passengerJourney =
    groupJourney.passengerJourneys && groupJourney.passengerJourneys.length > 0
      ? groupJourney.passengerJourneys[0]
      : undefined;
  if (!passengerJourney) return [];
  const servicesInAllLegs = passengerJourney.legs
    .flatMap((leg) => leg.trainInfo?.services)
    .filter(isNonNullable)
    .filter(isUniq);
  return servicesInAllLegs;
};

export const hasBedCabin = (
  groupJourney: SessionGroupJourneyFragment | OrderGroupJourneyFragment,
) => groupJourney.cabins?.some((cabin) => BED_CABIN_TYPES.has(cabin.type));

export const hasWheelchairPassenger = (
  groupJourney: SessionGroupJourneyFragment | OrderGroupJourneyFragment,
) =>
  !!groupJourney?.passengerJourneys?.find((pj) =>
    pj.otherFees?.find((fee) => fee.product === 'WHEELCHAIR'),
  ) ?? false;

export const isTravelPassUsage = (groupJourney: OrderGroupJourneyFragment) =>
  groupJourney.passengerJourneys?.some((passengerJourney) => passengerJourney.travelPassTicketId) ??
  false;

export const hasConscriptContractPassenger = (groupJourney: OrderGroupJourneyFragment) =>
  groupJourney.passengerJourneys?.some(
    (passengerJourney) => passengerJourney.passenger?.type === PassengerType.ConscriptContract,
  ) ?? false;

export const hasFdfContractPassenger = (groupJourney: OrderGroupJourneyFragment) =>
  groupJourney.passengerJourneys?.some(
    (passengerJourney) => passengerJourney.passenger?.type === PassengerType.FdfContract,
  ) ?? false;

export const allLegsAreCommuterLegs = (groupJourney?: Maybe<SessionGroupJourneyFragment>) => {
  return groupJourney?.passengerJourneys
    ?.at(0)
    ?.legs.every((leg) => isCommuterTrain(leg.trainType));
};

export const allLegsAreNoSeatReservationLegs = (groupJourney?: OrderGroupJourneyFragment) => {
  return groupJourney?.passengerJourneys
    ?.at(0)
    ?.legs.every((leg) => isTrainWithNoSeatReservation(leg.trainInfo?.trainType));
};

export const hasCancellationInsurance = (groupJourney: SessionGroupJourneyFragment) =>
  groupJourney.passengerJourneys?.some(
    (passengerJourney) => !!passengerJourney.cancellationInsuranceOffer,
  ) || false;

export const onlyAdultsAndAssistants = (groupJourney?: SessionGroupJourneyFragment): boolean => {
  if (!groupJourney) return false;
  const other = groupJourney.passengerJourneys?.find(
    (pj) =>
      pj.passenger?.type !== PassengerType.Adult && pj.passenger?.type !== PassengerType.Assistant,
  );
  return !other;
};

export const getExternalBusServiceOffers = (groupJourney: SessionGroupJourneyFragment) =>
  groupJourney.passengerJourneys?.flatMap(
    (passengerJourney) =>
      passengerJourney.externalServiceOffers?.filter(
        (externalServiceOffer) => externalServiceOffer.isExternalBusService,
      ) ?? [],
  ) ?? [];

export const getExternalBusServices = (groupJourney: OrderGroupJourneyFragment) =>
  groupJourney.passengerJourneys?.flatMap(
    (passengerJourney) =>
      passengerJourney.externalServices?.filter(
        (externalServiceOffer) => externalServiceOffer.isExternalBusService,
      ) ?? [],
  ) ?? [];

export const getPassengerByType =
  (passengerType: PassengerType) =>
  <T extends OrderGroupJourneyFragment | SessionGroupJourneyFragment>(
    groupJourney: T | undefined,
  ) =>
    // ts haves trouble with the union of arrays and complains on find "This expression is not callable."
    (groupJourney?.passengerJourneys as OrderPassengerJourneyFragment[])?.find(
      (p) => p.passenger?.type === passengerType,
    );

export const includesPassengerWithType = <
  T extends OrderGroupJourneyFragment | SessionGroupJourneyFragment,
>(
  passengerType: PassengerType,
): ((gj: T | undefined) => boolean) => {
  const fn = getPassengerByType(passengerType);

  return (groupJourney) => !!fn<T>(groupJourney);
};

export const hasHslOrWalttiTicket = (groupJourney: OrderGroupJourneyFragment) =>
  !!groupJourney.passengerJourneys
    ?.flatMap((passengerJourney) => passengerJourney.externalServices)
    .find(
      (externalService) =>
        externalService?.product === LineItemProduct.HslTicket ||
        externalService?.product === LineItemProduct.WalttiTicket,
    );

const getDiscountCodes = (
  groupJourney: SessionGroupJourneyFragment | OrderGroupJourneyFragment | undefined,
) =>
  groupJourney?.passengerJourneys?.flatMap(
    (passengerJourney) => passengerJourney.discountCodes ?? [],
  ) ?? [];

const getFdfDiscountCode = (
  groupJourney: SessionGroupJourneyFragment | OrderGroupJourneyFragment | undefined,
) =>
  getDiscountCodes(groupJourney).find((discountCode) => discountCode.type === DiscountCodeType.FDF);

export const includesFdfDiscount = (
  groupJourney: SessionGroupJourneyFragment | OrderGroupJourneyFragment | undefined,
) => !!getFdfDiscountCode(groupJourney);

const includesReplacementPassenger = includesPassengerWithType<OrderGroupJourneyFragment>(
  PassengerType.Replacement,
);
const includesSpecialFreePassPassenger = includesPassengerWithType<OrderGroupJourneyFragment>(
  PassengerType.SpecialFreePass,
);

export const isFreePassengerTypeWithBedCabin = (groupJourney: OrderGroupJourneyFragment) =>
  hasBedCabin(groupJourney) &&
  (includesReplacementPassenger(groupJourney) || includesSpecialFreePassPassenger(groupJourney));

export const hasPassengerCancellationInsurance = (journey: {
  passengerJourneys?: Maybe<Array<{ cancellationInsurance?: Maybe<object> }>>;
}) => journey.passengerJourneys?.some(({ cancellationInsurance }) => !!cancellationInsurance);

export const hasVehicleCancellationInsurance = (journey: {
  vehicles?: Maybe<Array<{ cancellationInsurance?: Maybe<object> }>>;
}) => journey.vehicles?.some(({ cancellationInsurance }) => !!cancellationInsurance);
