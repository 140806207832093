import ContentfulAppDownloadLinks from 'components/content/ContentfulAppDownloadLinks';
import ContentfulContentHighlightWithStripes from 'components/content/ContentfulContentHighlightWithStripes';
import ContentfulTable from 'components/content/ContentfulTable';

import { getValue } from 'utils/object';

import {
  LongTranslationInline,
  TranslationInline,
} from '../EmbeddedEntryInline/EmbeddedEntryInline';
import type { ContentfulNodeProps } from '../RichText.types';
import { createRendererWithNode } from '../utils/createRenderer';

enum EmbeddedEntryContentTypes {
  TABLE = 'table',
  ANCHOR_ID = 'anchorId',
  CONTENT_HIGHLIGHT_WITH_STRIPES = 'contentHighlightWithStripes',
  TRANSLATION = 'translation',
  LONG_TRANSLATION = 'longTranslation',
  APP_DOWNLOAD_LINKS = 'appDownloadLinks',
}

const EmbeddedTable = ({ node }: ContentfulNodeProps) => {
  const target = node.data.target;

  return <ContentfulTable content={target} />;
};

const EmbeddedAnchor = ({ node }: ContentfulNodeProps) => {
  const target = node.data.target;
  if (!target.fields?.customId) return null;
  return <a id={target.fields.customId} aria-hidden="true" data-testid="anchor-link" />;
};

const EmbeddedContentHighlightWithStripes = ({ node }: ContentfulNodeProps) => {
  const target = node.data.target;

  return <ContentfulContentHighlightWithStripes content={target} isEmbeddedEntry />;
};

const EmbeddedAppDownloadLinks = ({ node }: ContentfulNodeProps) => {
  const target = node.data.target;

  return <ContentfulAppDownloadLinks content={target} />;
};

const renderers: {
  [key in EmbeddedEntryContentTypes]: (node: ContentfulNodeProps['node']) => JSX.Element | null;
} = {
  [EmbeddedEntryContentTypes.TABLE]: createRendererWithNode(EmbeddedTable),
  [EmbeddedEntryContentTypes.ANCHOR_ID]: createRendererWithNode(EmbeddedAnchor),
  [EmbeddedEntryContentTypes.CONTENT_HIGHLIGHT_WITH_STRIPES]: createRendererWithNode(
    EmbeddedContentHighlightWithStripes,
  ),
  [EmbeddedEntryContentTypes.TRANSLATION]: createRendererWithNode(TranslationInline),
  [EmbeddedEntryContentTypes.LONG_TRANSLATION]: createRendererWithNode(LongTranslationInline),
  [EmbeddedEntryContentTypes.APP_DOWNLOAD_LINKS]: createRendererWithNode(EmbeddedAppDownloadLinks),
};

export const EmbeddedEntry = ({ node }: ContentfulNodeProps) => {
  const contentId: string | undefined = node.data.target?.sys?.contentType?.sys?.id;
  const renderer = (contentId && getValue(renderers, contentId)) || null;
  return renderer && renderer(node);
};
