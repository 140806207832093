import classNames from 'classnames';
import Image from 'next/image';

import { SLICE } from '@vrfi/web-components';

import { Text } from 'components/cssModules';
import { ArrowRightIcon } from 'components/ui/Icons';
import Link from 'components/ui/Link';
import Source from 'components/ui/Source';
import { Container } from 'components/ui/grid';

import { parseContentfulLinkReference } from 'content/restContent';

import { margin } from 'styles/compLib';

import { ContentComponentProps } from 'types/contentComponent';

import { parseEntities } from 'utils/string';

import styles from './ContentfulContentHighlightWithStripes.module.css';

type ComponentProps = ContentComponentProps<'contentHighlightWithStripes'> & {
  isEmbeddedEntry?: boolean;
};

const ContentfulContentHighlightWithStripes = (props: ComponentProps) => {
  const { isEmbeddedEntry } = props;
  const { textContent, linkLabel, linkReference, darkMode } = props.content.fields;
  if (!textContent) return null;

  const componentContent = (
    <div data-testid="contentHighlightWithStripes">
      <Text title1 as="h2" grey10={!darkMode} white={!!darkMode} className={margin.s00}>
        {parseEntities(textContent)}
      </Text>

      {linkLabel && linkReference && (
        <Link
          href={parseContentfulLinkReference(linkReference).url}
          className={styles.link}
          style={{ '--link-color': darkMode ? 'white' : 'var(--color-blue10)' }}
        >
          <Text title5 as="span" className={margin.s12Right}>
            {linkLabel}
          </Text>

          <ArrowRightIcon size={1.25} />
        </Link>
      )}
    </div>
  );

  return (
    <section
      className={classNames(styles.wrapper, {
        [styles.dark]: darkMode,
        [styles.embeddedEntry]: isEmbeddedEntry,
      })}
    >
      <picture className={styles.bgImage}>
        <Source
          alt=""
          media={`(max-width: ${SLICE.SM.to}px)`}
          src="/images/svg/misc/stripes-horizontal.svg"
          fill
          unoptimized
        />

        <Image alt="" src="/images/svg/misc/stripes.svg" fill unoptimized />
      </picture>
      {isEmbeddedEntry ? (
        componentContent
      ) : (
        <Container
          className={styles.container}
          data-testid="ContentfulContentHighlightWithStripes-inner-container"
        >
          {componentContent}
        </Container>
      )}
    </section>
  );
};

export default ContentfulContentHighlightWithStripes;
