import classNames from 'classnames';
import { memo } from 'react';

import utils from '@vrfi/web-components/css/utils.module.css';

import { Box, Text } from 'components/cssModules';
import { MarkdownRenderer } from 'components/ui/MarkdownRenderer';

import { margin } from 'styles/compLib';

import { isArray, isStringArray } from 'types/typeGuards';

import styles from './ContentfulTable.module.css';

interface Props {
  content: ContentfulWeb.TypeTable;
  inContainer?: boolean;
  ['data-testid']?: string;
}

const ContentfulTable = (props: Props) => {
  const {
    content: {
      sys: { id },
      fields: { table, tableIngress, title },
    },
  } = props;

  if (
    isArray(table) ||
    !table ||
    !isArray(table.tableData) ||
    !table.tableData.every(isStringArray)
  ) {
    return null;
  }

  const hasTitle = title != null && title.trim() !== '';
  const hasIngress = tableIngress != null && tableIngress.trim() !== '';
  const [head, ...body] = table.tableData as string[][];

  return (
    <Box
      as={hasTitle ? 'section' : 'div'}
      spacing="s08Column"
      className={hasTitle || hasIngress ? margin.s32Block : undefined}
      data-testid={`table-${id}`}
    >
      {hasTitle && (
        <Text title2 data-testid="ContentfulTable-title">
          {title}
        </Text>
      )}
      {hasIngress && (
        <Text as="p" data-testid="ContentfulTable-ingress" semibold size={20}>
          {tableIngress}
        </Text>
      )}
      <table
        className={classNames(styles.table, { [styles.manyCols]: head.length > 2 })}
        data-testid="ContentfulTable-table"
      >
        <thead className={head.length > 2 ? utils.visuallyHiddenToSM : undefined}>
          <tr>
            {head.map((cell, index) => (
              <Text as="th" paragraph={16} key={`th${id}${index}`} id={`${id}${index}`} semibold>
                <MarkdownRenderer>{cell}</MarkdownRenderer>
              </Text>
            ))}
          </tr>
        </thead>
        <tbody>
          {body.map((data, index) => (
            <tr key={`tr${index}`}>
              {data.map((cell, index) => (
                <td key={`td${id}${index}`} headers={`th${id}${index}`}>
                  <Text as="header" semibold aria-hidden>
                    {head[index]}
                  </Text>

                  <Text as="div">
                    <MarkdownRenderer>{cell}</MarkdownRenderer>
                  </Text>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
};

export default memo(ContentfulTable);
