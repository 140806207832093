import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";
import type { TypeAnchorIdSkeleton } from "./TypeAnchorId";
import type { TypeSlugSkeleton } from "./TypeSlug";

export interface TypeSlugWithParametersFields {
    title?: EntryFieldTypes.Symbol;
    pageSlug: EntryFieldTypes.EntryLink<TypeSlugSkeleton>;
    anchorId?: EntryFieldTypes.EntryLink<TypeAnchorIdSkeleton>;
    parameters?: EntryFieldTypes.Symbol;
}

export type TypeSlugWithParametersSkeleton = EntrySkeletonType<TypeSlugWithParametersFields, "slugWithParameters">;
export type TypeSlugWithParameters<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeSlugWithParametersSkeleton, Modifiers, Locales>;

export function isTypeSlugWithParameters<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeSlugWithParameters<Modifiers, Locales> {
    return entry.sys.contentType.sys.id === 'slugWithParameters'
}
