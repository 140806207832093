import { IconProps } from '@vrfi/web-components';

import { getIconComponent } from 'components/ui/Icons/getIconComponent';

interface Props extends IconProps {
  icon: string | null;
}

export function DynamicIconLoad({ icon, ...props }: Props) {
  const Component = icon && getIconComponent(icon);
  return Component ? <Component {...props} /> : null;
}
