'use client';

import classNames from 'classnames';
import { ComponentPropsWithoutRef, memo, useCallback, useEffect } from 'react';

import { Notification as CmNotification } from 'components/cssModules/Notification';
import { MarkdownRenderer } from 'components/ui/MarkdownRenderer';

import RichText from 'content/RichText';

import { useIsToSlice } from 'hooks/mediaQuery';

import { isContentfulDocument } from 'types/typeGuards';

import { ColorEnum } from '@vrfi/web-components';

import { ContentWrapper } from 'components/ui/ContentWrapper';
import { NotificationType } from '../types';
import styles from './Notification.module.css';
import { Props } from './Notification.types';

type NotificationVariants = NonNullable<ComponentPropsWithoutRef<typeof CmNotification>['variant']>;

const typeMap = new Map<NotificationType, NotificationVariants>([
  [NotificationType.Alert, 'alert'],
  [NotificationType.MajorNotice, 'major'],
  [NotificationType.MinorNotice, 'minor'],
]);

const BackgroundColor = {
  Alert: ColorEnum.red10,
  MajorNotice: ColorEnum.yellow27,
  MinorNotice: ColorEnum.blue20,
};

const Notification = ({
  id,
  className,
  children,
  title,
  body,
  label,
  link,
  type,
  ['data-testid']: testId,
  setNotificationRead,
  autoDismissAfterSeconds,
  ...restProps
}: Props) => {
  const isSmallScreen = useIsToSlice('SM');
  const bodyType = isSmallScreen ? 'collapsible' : 'alwaysShown';

  const onClose = useCallback(() => {
    setNotificationRead(id);
  }, [id, setNotificationRead]);

  useEffect(() => {
    if (!autoDismissAfterSeconds) {
      return;
    }
    const timerId = setTimeout(onClose, autoDismissAfterSeconds * 1000);
    return () => clearTimeout(timerId);
  }, [autoDismissAfterSeconds, onClose]);

  return (
    <ContentWrapper className={styles.notificationWrapper} backgroundColor={BackgroundColor[type]}>
      <CmNotification
        key={bodyType}
        icon="alert"
        data-notice-type={typeMap.get(type)}
        data-notice
        aria-live="polite"
        data-testid={testId ? testId : 'notice'}
        role="alert"
        variant={typeMap.get(type)}
        title={title}
        bodyType={bodyType}
        roundedCorners="s08"
        onClose={onClose}
        dynamicHeight
        showClose
        className={classNames(className, styles.notification)}
        {...restProps}
      >
        <>
          {isContentfulDocument(body) ? (
            <RichText document={body} />
          ) : (
            <MarkdownRenderer withParagraphs>{body}</MarkdownRenderer>
          )}
        </>
      </CmNotification>
    </ContentWrapper>
  );
};

export default memo(Notification);
