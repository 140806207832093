'use client';

import { ReactNode, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

export const Portal = ({ children }: { children: ReactNode }) => {
  const [container] = useState(() => {
    const container = document.createElement('div');
    document.body.appendChild(container);
    return container;
  });

  useEffect(() => {
    return () => {
      document.body.removeChild(container);
    };
  }, [container]);

  return ReactDOM.createPortal(children, container);
};
