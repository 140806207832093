import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { LOCALE } from 'constants/i18n';

import { fromEntries } from 'utils/object';

import { PageRouteKey } from 'build/data/getPageRoutes';
import pageRoutes from 'data/pageRoutes.json';

const findPage = (key: PageRouteKey) => {
  const page = pageRoutes.find((page) => page.template === key);

  if (!page) {
    throw new Error(`Cannot find page with "${key}"`);
  }

  return page;
};

export const usePages = () => {
  const { locale } = useIntl();

  const getPageSlug = useCallback(
    (key: PageRouteKey, otherLocale?: LOCALE) => findPage(key).slug[otherLocale || locale],
    [locale],
  );

  const getPageTitle = useCallback(
    (key: PageRouteKey, otherLocale?: LOCALE) => findPage(key).title?.[otherLocale || locale],
    [locale],
  );

  return { getPageSlug, getPageTitle };
};

export const usePageSlug = (key: PageRouteKey, otherLocale?: LOCALE) => {
  const { locale } = useIntl();
  const selectedLocale = otherLocale || locale;
  return useMemo(() => findPage(key).slug[selectedLocale], [key, selectedLocale]);
};

export const usePageTitle = (key: PageRouteKey, otherLocale?: LOCALE) => {
  const { locale } = useIntl();
  const selectedLocale = otherLocale || locale;
  return useMemo(() => findPage(key).title?.[selectedLocale], [key, selectedLocale]);
};

export const usePageSlugs = (otherLocale?: LOCALE) => {
  const { locale } = useIntl();
  const selectedLocale = otherLocale || locale;

  return useMemo(
    () =>
      fromEntries(pageRoutes.map((page) => [page.template, page.slug[selectedLocale]] as const)),
    [selectedLocale],
  );
};
