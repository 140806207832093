import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";

export interface TypeAnchorIdFields {
    customId?: EntryFieldTypes.Symbol;
}

export type TypeAnchorIdSkeleton = EntrySkeletonType<TypeAnchorIdFields, "anchorId">;
export type TypeAnchorId<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeAnchorIdSkeleton, Modifiers, Locales>;

export function isTypeAnchorId<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeAnchorId<Modifiers, Locales> {
    return entry.sys.contentType.sys.id === 'anchorId'
}
