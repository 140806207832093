import { ImageProps, getImageProps } from 'next/image';
import { LinkHTMLAttributes, memo } from 'react';

type Props = ImageProps & {
  media: string;
};

const linkProps: LinkHTMLAttributes<HTMLLinkElement> = {
  rel: 'preload',
  as: 'image',
  fetchPriority: 'high',
};

function Source({ media, ...props }: Props) {
  const {
    props: { srcSet: imageSrcSet, sizes, height, width, style, src },
  } = getImageProps(props);

  return (
    <>
      <source
        media={media}
        srcSet={imageSrcSet ?? src}
        width={width}
        height={height}
        style={style}
      />
      {props.priority && <link {...linkProps} imageSrcSet={imageSrcSet} sizes={sizes} />}
    </>
  );
}

export default memo(Source);
