/**
 * Registers a dialog polyfill as needed.
 *
 * @see https://github.com/GoogleChrome/dialog-polyfill
 * @see https://caniuse.com/dialog
 */
export async function polyfillDialog(
  dialog: HTMLDialogElement | null,
  callback?: (dialog: HTMLDialogElement) => void,
) {
  if (dialog && typeof dialog.showModal !== 'function') {
    // hack `instanceof` to work
    if (!window.HTMLDialogElement) {
      window.HTMLDialogElement = window.HTMLElement as typeof window.HTMLDialogElement;
    }
    await import('dialog-polyfill').then((polyfill) => polyfill.default.registerDialog(dialog));
  }

  if (dialog && callback) {
    callback(dialog);
  }

  return dialog;
}
