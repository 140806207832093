'use client';

import { forwardRef, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { CmNotificationProps, createNotification } from '@vrfi/web-components';

import notification from '@vrfi/web-components/css/notification.module.css';

import { GenericMessage } from 'constants/i18n.messages';

const CmNotification = createNotification({ notification });

export const Notification = forwardRef<HTMLDivElement, Omit<CmNotificationProps, 'texts'>>(
  (props, ref) => {
    const { formatMessage } = useIntl();

    const texts = useMemo(
      (): CmNotificationProps['texts'] => ({
        close: formatMessage({ id: GenericMessage.CLOSE }),
        showLess: formatMessage({ id: GenericMessage.READ_LESS }),
        showMore: formatMessage({ id: GenericMessage.READ_MORE }),
      }),
      [formatMessage],
    );

    return <CmNotification {...props} texts={texts} ref={ref} />;
  },
);

Notification.displayName = 'Notification';
