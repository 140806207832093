import { ReactNode } from 'react';
import { RemoveScroll as RemoveScrollLib } from 'react-remove-scroll/UI';
import { sidecar } from 'use-sidecar';

const removeScrollSideCar = sidecar(
  () => import(/* webpackPrefetch: true */ 'react-remove-scroll/sidecar'),
);

export const RemoveScroll = ({
  disabled,
  children,
}: {
  disabled?: boolean;
  children: ReactNode;
}) => {
  return (
    <RemoveScrollLib sideCar={removeScrollSideCar} enabled={!disabled}>
      {children}
    </RemoveScrollLib>
  );
};
