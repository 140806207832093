import { StorageKey } from 'types/stateStore';

import { useStoredState } from './useStoredState';

export const useCookieConsent = () => {
  const [{ consented, levels }, setCookieConsent, clearCookieConsent] = useStoredState(
    StorageKey.CookieConsent,
  );

  const level =
    (levels.level4 && 4) ||
    (levels.level3 && 3) ||
    (levels.level2 && 2) ||
    (levels.level1 && 1) ||
    0;

  return [{ consented, levels, level }, setCookieConsent, clearCookieConsent] as const;
};
