import { useMemo } from 'react';

import { ContentComponentProps } from 'types/contentComponent';

import produce from 'immer';
import ContentfulLinkWithImage from '../ContentfulLinkWithImage';

export const ContentfulAppDownloadLinks = (props: ContentComponentProps<'appDownloadLinks'>) => {
  const { appLinks } = props.content.fields;

  const smallestImage: { width: number; height: number } = useMemo(() => {
    const linkWithSmallestImage = appLinks.reduce((prev, curr) => {
      return (prev?.fields.image?.fields.file?.details.image?.width ?? 0) <
        (curr?.fields.image?.fields.file?.details.image?.width ?? 0)
        ? prev
        : curr;
    }, appLinks.at(0));

    return {
      width: linkWithSmallestImage?.fields.image?.fields.file?.details.image?.width ?? 0,
      height: linkWithSmallestImage?.fields.image?.fields.file?.details.image?.height ?? 0,
    };
  }, [appLinks]);

  const changeImageSize = (content: ContentfulWeb.TypeLinkWithImage) =>
    produce(content, (draft) => {
      if (draft.fields.image?.fields.file) {
        draft.fields.image.fields.file.details.image = smallestImage;
      }
    });

  return (
    <div data-box="wrap" data-gap="s08" data-testid="contentful-download-links">
      {appLinks.map(
        (appLink) =>
          appLink && (
            <ContentfulLinkWithImage key={appLink.sys.id} content={changeImageSize(appLink)} />
          ),
      )}
    </div>
  );
};
