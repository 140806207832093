import { useMemo } from 'react';

import { useLocation } from 'utils/navigation';
import qs from 'utils/qs';

const parseSalesSessionUrlParam = (search: string) => {
  const { saleId, journeyKey } = qs.parse(search);

  return {
    saleId: typeof saleId === 'string' ? saleId : undefined,
    journeyKey: typeof journeyKey === 'string' ? journeyKey : undefined,
  };
};

export const useSalesSessionUrlParam = () => {
  const location = useLocation();

  return useMemo(() => parseSalesSessionUrlParam(location.search), [location.search]);
};
