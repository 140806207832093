import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

import {
  ReCreateSalesSessionMutation,
  ReCreateSalesSessionMutationVariables,
} from 'backend/types.codegen';
import { handleSalesSessionErrors } from 'backend/utils/salesSessionErrors';

import { SalesSessionFragment } from './fragments';

const mutation = gql`
  ${SalesSessionFragment}

  mutation reCreateSalesSession($orderNumber: ID!) {
    reCreateSalesSession(orderNumber: $orderNumber) {
      ...SalesSession
    }
  }
`;

export const useReCreateSalesSession = () => {
  const tuple = useMutation<ReCreateSalesSessionMutation, ReCreateSalesSessionMutationVariables>(
    mutation,
  );

  handleSalesSessionErrors(tuple[1].error);

  return tuple;
};
