import { MutationResult, QueryResult } from '@apollo/client';
import { useMemo } from 'react';

import { SalesSessionFragment } from 'backend/types.codegen';

import { Maybe } from 'types/utils';

import { getJourney } from 'utils/salesSession';

import { useSalesSessionUrlParam } from './useSalesSessionUrlParam';

export const useWrapSalesSession = (
  salesSession: Maybe<SalesSessionFragment>,
  result: QueryResult<any, any> | MutationResult<any>,
) => {
  const { journeyKey } = useSalesSessionUrlParam();

  return useMemo(() => {
    const journey = salesSession && journeyKey ? getJourney(salesSession, journeyKey) : undefined;

    return {
      salesSession: salesSession ?? undefined,
      loading: result.loading || !result.called,
      error: result.error,
      journey,
    };
  }, [salesSession, result, journeyKey]);
};

export type WrappedSalesSession = ReturnType<typeof useWrapSalesSession>;
