'use client';
import { memo, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';

import { CmLoadingProps, createLoading } from '@vrfi/web-components';

import loading from '@vrfi/web-components/css/loading.module.css';

import { TrafficMessage } from 'constants/i18n.messages';

import { reduceMotion } from 'utils/a11y';

const LoadingComponent = createLoading({ loading });

/** Pass `aria-live="polite"` if the loader is not the page's main loader. */
export const Loading = memo(function Loading({ size = 10, ...props }: CmLoadingProps) {
  const { formatMessage } = useIntl();

  const ref = useRef<HTMLDivElement>(null);
  const isAssertive = props['aria-live'] === 'assertive';

  useEffect(() => {
    const loading = ref.current?.parentElement;
    if (!loading || !isAssertive) return;

    scrollIntoView(loading, {
      block: 'center',
      inline: 'nearest',
      behavior: reduceMotion ? 'auto' : 'smooth',
    });
  }, [isAssertive]);

  return (
    <LoadingComponent
      aria-atomic
      aria-busy
      aria-live="assertive"
      aria-valuetext={formatMessage({ id: TrafficMessage.LOADING })}
      data-testid="loading-indicator"
      role="progressbar"
      center
      size={size}
      {...props}
    >
      <div ref={ref} />
    </LoadingComponent>
  );
});
