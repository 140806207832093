import type { Block, Inline } from '@contentful/rich-text-types';
import { ComponentType } from 'react';

import { ContentfulNodeProps } from '../RichText.types';

type CT = ComponentType<{ node: Block | Inline }>;

/** Creates a creates a RenderNode renderer, but ignores passing children */
export function createRendererWithNode(Component: CT) {
  const component = (node: ContentfulNodeProps['node']) => <Component node={node} />;
  return component;
}
