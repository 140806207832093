import { gql, useQuery } from '@apollo/client';
import 'backend/types.codegen';
import { GetDeliveryEmailQuery, GetDeliveryEmailQueryVariables } from 'backend/types.codegen';
import { useIsAuthenticated } from 'hooks/useIsAuthenticated';

const GET_DELIVERY_EMAIL = gql`
  query getDeliveryEmail {
    user {
      info {
        activeEmail
      }
    }
  }
`;

export const useGetDeliveryEmail = () => {
  const isAnonymous = !useIsAuthenticated();

  return useQuery<GetDeliveryEmailQuery, GetDeliveryEmailQueryVariables>(GET_DELIVERY_EMAIL, {
    ssr: false,
    fetchPolicy: 'cache-first',
    skip: isAnonymous,
  });
};
